export const environment = {
    name: 'release',
    production: true,
    testing: false,
    api: 'https://api.release.haleo.ca/api/v1/',
    salesForceDomain: 'https://haleo2--release.sandbox.lightning.force.com/',
    systemLoadApi: 'https://api-qa-status.haleo.ca/api/v1/',
    apiDomain: 'api.release.haleo.ca',
    resourcesUrl:
        'https://haleo-backend-release-e2eaf6dybefvanfq.z01.azurefd.net/storage/backend-release/resources/',
    stripeKey: 'pk_test_e03scT46QvaFOtvXW7NFii7U00Z0jzsYXN',
    programKeys: {
        sleepCoachingProgram: 'sleepCoachingProgram',
        cbtiProgram: 'cbtiProgram',
        cbtiShiftWorkProgram: 'cbtiShiftWorkProgram',
        selfGuidedCbtiProgram: 'selfGuidedCbtiProgram',
        cbtiNightmareProgram: 'cbtiNightmareProgram',
        cbtiShiftWorkNightmareProgram: 'cbtiShiftWorkNightmareProgram',
        sleepCoachingNewParentProgram: 'sleepCoachingNewParentProgram',
        cbtiNewParentProgram: 'cbtiNewParentProgram',
    },
    screenerRecommendations: {
        cbtiProgram: 'cbtiProgram',
        cbtiShiftWorkNightmareProgram: 'cbtiShiftWorkNightmareProgram',
        keepGoodWork: 'keepGoodWork',
        sleepCoachingProgram: 'sleepCoachingProgram',
        seeDoctorDontComeBack: 'seeDoctorDontComeBack',
        seeDoctorComeBack: 'seeDoctorComeBack',
        cbtiNightmareProgram: 'cbtiNightmareProgram',
        swsdWaitlist: 'swsdWaitlist',
        cbtiShiftWorkProgram: 'cbtiShiftWorkProgram',
        sleepCoachingProgramNewParent: 'sleepCoachingNewParentProgram',
        cbtiDelayedPhase: 'cbtiDelayedPhase',
    },
    cacheValidity: {
        short: 30000,
    },
    sentryDSN: '',
    amplitudeKey: '',
    firebaseConfig: {
        apiKey: '',
        authDomain: '',
        projectId: '',
        storageBucket: '',
        messagingSenderId: '',
        appId: '',
        measurementId: '',
    },
    appId: '',
    zoomApiKey: '',
    sleepCountry: { zendeskAccount: '', olark: '' },
    broadcasting: {
        authEndpoint: 'https://api.release.haleo.ca/broadcasting/auth',
        host: 'broadcast.release.haleo.ca',
        key: 'IK71fHZ7PgaFW0474wYlE31l7KHg1FN4jKMAaY0glswhsbgo0ck90Lj24DZU',
        port: 443,
        tls: true,
    },
    growthBookKey: '',
    screenerV3Url: 'https://questionnaire.release.haleoclinic.com/',
};
