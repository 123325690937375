import { environment as base } from '@haleo-frontend/utils';

export const environment = {
    ...base,
    appId: 'ca.haleo.screener-app.release',
    sentryDSN: 'https://0c5f0cb86920459cabec4f47ef4e0c2c@sentry-relay.haleo.ca/4504323173515264',
    firebaseConfig: {
        apiKey: 'AIzaSyADfsmoPXbLvcJ0BD9Ux2n_cj45O0e9Z_A',
        authDomain: 'haleo-screener-v3-release.firebaseapp.com',
        projectId: 'haleo-screener-v3-release',
        storageBucket: 'haleo-screener-v3-release.appspot.com',
        messagingSenderId: '101900236439',
        appId: '1:101900236439:web:5e198f2c0ff3cf78a79bd2',
        measurementId: 'G-PQV5PGFCD8'
    },
    fileReplacements: [
        {
          "replace": "src/index.html",
          "with": "src/index.release.html"
        }
    ],
    recaptchaSiteKey: '6Lep_0EnAAAAAKvxim2UZcXDQqd_7oUO6oIa1QzJ',
};
