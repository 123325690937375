import { Injectable } from '@angular/core';
import { Client } from '@haleo-frontend/data-access/models';
import { BrowserTracing } from "@sentry/tracing";
import * as SentryCapacitor from "@sentry/capacitor";
import * as SentryAngular from "@sentry/angular";
import { Replay } from "@sentry/replay";

import { EnvironmentUtils } from './environment.utils';

@Injectable({
    providedIn: 'root'
})
export class LogUtils {

    static appVersion = '1.2.6815';
    static sentry: any;

    static init(ios: boolean, capacitor: boolean, appKey: string) {

        let sourceMapPrefix: string;

        if (capacitor) {
            sourceMapPrefix = ios ? 'ios/' + appKey : 'android/' + appKey;
        } else {
            sourceMapPrefix = 'web';
        }

        LogUtils.sentry = SentryCapacitor;

        if (EnvironmentUtils.env.sentryDSN) {

            let replaysSessionSampleRate = 0.1;

            if (EnvironmentUtils.env.name === 'alpha' || EnvironmentUtils.env.name === 'qa') {
                replaysSessionSampleRate = 1;
            }

            const configuration: any = {
                dsn: EnvironmentUtils.env.sentryDSN,
                release: LogUtils.appVersion,
                dist: '1',
                environment: EnvironmentUtils.env.appId,
                integrations: [new Replay()],
                tracesSampleRate: 0.1,
                replaysSessionSampleRate,
                replaysOnErrorSampleRate: 1,
                beforeSend(event: any) {

                    if (event.culprit) {
                        event.culprit = event.culprit.substring(event.culprit.lastIndexOf('/'));
                    }

                    const stacktrace = event.stacktrace ||
                        event.exception &&
                        event.exception.values[0].stacktrace;
                    if (stacktrace) {
                        stacktrace.frames.forEach((frame: any) => frame.filename = frame.filename.replace('app:///', sourceMapPrefix + '/'));
                    }

                    return event;
                }
            };

            if (capacitor) {

                configuration.integrations.push(new BrowserTracing({
                    tracingOrigins: [EnvironmentUtils.env.api],
                }));

                LogUtils.sentry.init(configuration, SentryAngular.init);
            } else {
                LogUtils.sentry.init(configuration);
            }
        }
    }

    static setUser(data: Client) {
        LogUtils.sentry.configureScope((scope: any) => scope.setUser({id: data.id.toString()}));
    }

    static captureException(error: any) {
        LogUtils.sentry.captureException(error);
    }

    static get debug() {
        return console.debug.bind(console);
    }

    static get error() {
        return console.error.bind(console);
    }
}
